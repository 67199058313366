'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { WithLoginState, loginStatusContext } from '@next-public-site/src/hoc/withLoginStatePublicSite'
import { useSignout } from '@next-public-site/src/hooks/useSignout'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import React, { FC, useContext } from 'react'
import { css } from 'styled-system/css'

export const ProtectedAppProvider: FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const WithAmplify = dynamic(() => import('@next-public-site/src/hoc/withAmplify').then(mod => mod.WithAmplify))

  return (
    <WithAmplify>
      <WithLoginState>
        <Component>{children}</Component>
      </WithLoginState>
    </WithAmplify>
  )
}

const Component: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { signOut } = useSignout()
  const loginStatus = useContext(loginStatusContext)
  const pathname = usePathname()
  // Placing the UI for Login page here instead of route /login:
  // it needs to have the withAmplify and withLoginState HOCs, and those HOC's are conditionally applied to the ProtectedAppProvider.
  // If I try to add to Login route, then I need to either redeclare the HOC's or make them commin in the layout. The former introduces a bug
  // in which the login state is not shared between the ProtectedAppProvider and the Login component. The latter actually might work... but in
  // this way, all that pertains to the login state is in one place.

  if (loginStatus === 'loading') {
    return null
  }

  if (loginStatus === 'loggedOut') {
    const Login = dynamic(() => import('app/_components/Login').then(mod => mod.Login))
    return <Login />
  }
  return (
    <>
      {children}
      <div
        className={css({ bgColor: '$pri' })}
        key={pathname} // force component to re-render when pathname changes. It won't show for 3s. The reason is that if not, it will show at the very top of the page until the rest of the document renders. And that looks bad.
      >
        <Gutter variant={'short'}>
          <Text
            variant="overline"
            isLink={true}
            css={{ color: '$gs1', opacity: '[0.6 ]' }}
            onClick={() => signOut({ global: false })}>
            Disconnect from Protected Site
          </Text>
        </Gutter>
      </div>
    </>
  )
}
export default ProtectedAppProvider
