// import { MarkdownToJsx } from '../../basicUI/MarkdownToJsx'
import dynamic from 'next/dynamic'
import { ComponentType, FC, useMemo } from 'react'
import { css } from 'styled-system/css'

import { ChatbotProps, messageContainer } from '.'
import { Avatar } from '../Avatar'
import { CustomFlex } from '../CustomFlex'
import { SmartToyIcon } from '../CustomIcons/SmartToyIcon'
import { ShoImage } from '../ShoImage'
import { Text } from '../Text'

export const ConversationMessage: FC<{
  message: ChatbotProps['messages'][0]
  role: ChatbotProps['messages'][0]['role']
  isFullScreen: boolean
  chatbotProfileImage?: ChatbotProps['chatbotProfileImage']
  userFirstName?: ChatbotProps['userFirstName']
  userLastName?: ChatbotProps['userLastName']
  time?: string
  isVisible: boolean
}> = ({ message, role, isFullScreen, chatbotProfileImage, userFirstName, userLastName, time, isVisible }) => {
  // We do this as to not import MarkdownToJsx when it's not needed
  const MarkdownToJsx = useMemo<ComponentType<{ markdown: string }> | null>(() => {
    if (!isVisible) return null
    return dynamic(() => import('../MarkdownToJsx').then(mod => mod.MarkdownToJsx))
  }, [isVisible])

  if (!isVisible) return null

  return (
    <CustomFlex direction="column" gap="1">
      <Text
        variant="caption"
        css={css.raw({ mx: '$15', color: '$gs11', alignSelf: role === 'assistant' ? 'flex-start' : 'flex-end' })}>
        {time}
      </Text>
      <CustomFlex
        align="end"
        gap="3"
        justify={role === 'assistant' ? 'start' : 'end'}
        // css={{ paddingLeft: '$2', paddingRight: '$2' }}
      >
        {role === 'assistant' && (
          <>
            {chatbotProfileImage ? (
              <ShoImage
                src={chatbotProfileImage.src}
                width={chatbotProfileImage.width}
                height={chatbotProfileImage.height}
                alt="AI Chatbot Profile Image"
                sizes="38px"
                imageCss={css.raw({
                  width: '[38px]',
                  height: '[38px]',
                  objectFit: 'cover',
                  borderRadius: '$round',
                })}
              />
            ) : (
              <Avatar customReactIcon={<SmartToyIcon />} />
            )}
          </>
        )}
        <div
          className={messageContainer({
            role,
            isFullScreen,
            isErrorMessage: message?.type === 'error',
          })}>
          {MarkdownToJsx && <MarkdownToJsx markdown={message.content} />}
        </div>
        {role === 'user' && <Avatar firstName={userFirstName} lastName={userLastName} />}
      </CustomFlex>
    </CustomFlex>
  )
}
