import useIsMounted from 'ismounted'
import { useState } from 'react'

interface StateProps {
  loading: boolean
  error: null | Error
}

type signOut = (options?: { global: boolean }) => Promise<void>

export const useSignout = (): StateProps & { signOut: signOut } => {
  const [state, setState] = useState({
    loading: false,
    error: null,
  })
  const isMounted = useIsMounted()

  const signOut: signOut = async options => {
    const { global } = options || { global: true }
    setState({ loading: true, error: null })
    try {
      const signOut = await import('aws-amplify/auth').then(mod => mod.signOut)
      await signOut({ global })
      // clear localStorage on logout
      localStorage.clear()
      if (isMounted.current) setState({ loading: false, error: null })
    } catch (error: any) {
      console.log('error signing out: ', error)
      if (isMounted.current) setState({ loading: false, error })
    }
  }

  return {
    ...state,
    signOut,
  }
}
