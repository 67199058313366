import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'
import React from 'react'
import { css, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

export interface ScrollAreaProps extends ScrollAreaPrimitive.ScrollAreaProps {
  /**
   * The orientation of the scrollbar
   */
  orientation?: 'horizontal' | 'vertical'

  /**
   * Content for button trigger.
   */
  children?: React.ReactNode

  /**
   * The maximum height of an scroll area.
   */
  maxHeight?: number

  /**
   * The maximum width of an scroll area.
   */
  maxWidth?: number | string

  /**
   *  Give class name to icon button.
   */
  className?: string

  /**
   *  Set the css property.
   */
  css?: SystemStyleObject
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({
  type = 'hover',
  dir = 'ltr',
  scrollHideDelay = 60002,
  orientation = 'vertical',
  maxHeight,
  maxWidth,
  className,
  css: cssProp = {},
  children,
  ...props
}) => {
  const width = orientation === 'horizontal' && maxWidth ? `${maxWidth}px` : '100%'
  const _maxHeight = orientation === 'vertical' && maxHeight ? `${maxHeight}px` : '100%'

  return (
    <ScrollAreaPrimitive.Root
      {...props}
      dir={dir}
      type={type}
      scrollHideDelay={scrollHideDelay}
      style={{ maxHeight: _maxHeight, width }}>
      <ScrollAreaPrimitive.Viewport
        className={cx(
          css(
            {
              width: '$full',
              height: '$full',
            },
            cssProp,
          ),
          className,
        )}>
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollAreaPrimitive.Scrollbar orientation={orientation} className={styledScrollbar}>
        <ScrollAreaPrimitive.Thumb className={styledThumb} />
      </ScrollAreaPrimitive.Scrollbar>
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  )
}

const styledScrollbar = css({
  display: 'flex',
  userSelect: 'none',
  touchAction: 'none',
  padding: '$1',
  width: '[4px !important]',
  transitionProperty: 'background',
  transitionDuration: '$fast',
  transitionTimingFunction: 'out',
  '&[data-orientation="vertical"]': {
    width: '[4px]',
  },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: '[4px]',
  },
})

const styledThumb = css({
  flex: '1',
  background: '$gs6',
  borderWidth: '$1',
  borderStyle: 'solid',
  borderColor: '$gs8',
  position: 'relative',
  borderRadius: '$6',
  zIndex: '[100]',
  _hover: {
    background: '$gs4',
    cursor: 'pointer',
  },
})
