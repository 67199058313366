import { ArchiveIcon, DotsHorizontalIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons'
import { convertIsoDateToTimestamp } from 'next-app/src/utils/date-utils'
import { FC, useEffect, useState } from 'react'
import { css, cva } from 'styled-system/css'

import { ChatbotProps, REACT_ICON_SIZE } from '.'
import { Box } from '../Box'
import { CustomFlex } from '../CustomFlex'
import { DropdownMenu } from '../Dropdowns/DropdownMenu'
import { Icon } from '../Icon'
import { Modal } from '../Modal'
import { Skeleton } from '../Skeleton'
import { Text } from '../Text'
import { Textfield } from '../Textfield'
import { Tooltip } from '../Tooltip'
import { headerIconStyledWithHover } from './'
import { ConversationMessage } from './ConversationMessage'

type ChatHistoryModalProps = {
  chatHistory?: ChatbotProps['chatHistory']
  onGetchatConversationsTitleList?: ChatbotProps['onGetchatConversationsTitleList']
  onGetConversationById?: ChatbotProps['onGetConversationById']
  onUpdateConversationTitle?: ChatbotProps['onUpdateConversationTitle']
  onChangeConversationId?: ChatbotProps['onChangeConversationId']
  onDeleteConversation?: ChatbotProps['onDeleteConversation']
  chatbotProfileImage?: ChatbotProps['chatbotProfileImage']
  userFirstName?: ChatbotProps['userFirstName']
  userLastName?: ChatbotProps['userLastName']
  isLoadingConversationsTitleList?: ChatbotProps['isLoadingConversationsTitleList']
  isLoadingConversation?: ChatbotProps['isLoadingConversation']
  isFullScreen: boolean
}

export const ChatHistoryModal: FC<ChatHistoryModalProps> = ({
  chatHistory,
  onGetchatConversationsTitleList,
  onGetConversationById,
  onUpdateConversationTitle,
  onChangeConversationId,
  onDeleteConversation,
  isFullScreen,
  chatbotProfileImage,
  userFirstName,
  userLastName,
  isLoadingConversationsTitleList,
  isLoadingConversation,
}) => {
  const [currentSelectedConversationId, setCurrentSelectedConversationId] = useState<string | null>(null)
  const [textfieldTitle, setTextfieldTitle] = useState<string>('')
  const [titleToEditConversationId, setTitleToEditConversationId] = useState<string | null>(null)

  const userHasnNoSavedConversations = !isLoadingConversationsTitleList && chatHistory?.length === 0

  useEffect(() => {
    // Select first conversation automatically
    const activeConversationId = chatHistory?.[0]?.id
    if (!currentSelectedConversationId && activeConversationId) {
      setCurrentSelectedConversationId(activeConversationId)
    }
  }, [chatHistory, currentSelectedConversationId])

  return onGetchatConversationsTitleList &&
    onGetConversationById &&
    onChangeConversationId &&
    chatHistory &&
    onDeleteConversation ? (
    <Modal
      title="Chat History"
      primaryBtnLabel="Select Conversation"
      tertiaryBtnLabel="Cancel"
      disablePrimaryBtn={!currentSelectedConversationId}
      primaryBtnCallback={() => {
        if (currentSelectedConversationId) {
          onChangeConversationId(currentSelectedConversationId)
        }
      }}
      onOpenChange={isOpen => {
        if (!isOpen) {
          setCurrentSelectedConversationId(null)
          setTitleToEditConversationId(null)
          setTextfieldTitle('')
        }
      }}
      size="large"
      openButton={
        <div>
          {/* need to surround by div for modal to trigger */}
          <Tooltip content="See Chat History">
            <Icon
              css={headerIconStyledWithHover}
              reactIcon={<ArchiveIcon />}
              size={REACT_ICON_SIZE}
              onClick={async () => {
                onGetchatConversationsTitleList()
              }}
            />
          </Tooltip>
        </div>
      }>
      <div className={container({ centerContent: userHasnNoSavedConversations })}>
        {userHasnNoSavedConversations ? (
          <CustomFlex css={css.raw({ textAlign: 'center', width: '[100%]' })} direction="column" gap={'3'}>
            <Text variant="body1" css={css.raw({ margin: '[0px auto]', display: 'inline-block' })}>
              You have not yet interacted with our AI chatbot!
            </Text>
            <Text variant="body2" css={{ margin: '[0px auto]', display: 'inline-block' }}>
              Conversation history will appear as you chat with our AI chatbot.
            </Text>
          </CustomFlex>
        ) : (
          <>
            <div className={titleColumn}>
              <div className={columnContainerForTitles}>
                {chatHistory?.length === 0 && isLoadingConversationsTitleList ? (
                  <Box>
                    <Skeleton height="caption" verticalSpacing="none" css={css.raw({ mb: '$5' })} />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="caption" verticalSpacing="none" css={css.raw({ mb: '$5', mt: '$8' })} />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="body1" verticalSpacing="medium" />
                    <Skeleton height="body1" verticalSpacing="medium" />
                  </Box>
                ) : (
                  chatHistory
                    ?.sort((a, b) => convertIsoDateToTimestamp(b.updatedAt) - convertIsoDateToTimestamp(a.updatedAt))
                    .map(({ id: conversationId, title, updatedAt }, index) => {
                      const isSelected = conversationId === currentSelectedConversationId
                      let titleToDisplay = ''

                      const currentTitle = getTitleToDisplayFromTimestamp(convertIsoDateToTimestamp(updatedAt))
                      let showPaddingTop = false

                      if (index === 0) {
                        titleToDisplay = currentTitle
                      } else {
                        const previousTitle = getTitleToDisplayFromTimestamp(
                          convertIsoDateToTimestamp(chatHistory[index - 1].updatedAt),
                        )
                        if (currentTitle !== previousTitle) {
                          titleToDisplay = currentTitle
                          showPaddingTop = true
                        }
                      }

                      return (
                        <div key={conversationId}>
                          <Text
                            variant="caption"
                            css={css.raw({ color: '$gs11', pb: '$1', paddingTop: showPaddingTop ? '$4' : '$0' })}>
                            {titleToDisplay}
                          </Text>
                          <div className={titleRow({ isSelected })}>
                            {titleToEditConversationId === conversationId ? (
                              <Textfield
                                name="title"
                                value={textfieldTitle}
                                inputVariant={'body2'}
                                fullWidth={true}
                                css={css.raw({
                                  px: '$1',
                                  py: '[5px]',
                                  borderRadius: '$3',
                                  '& > *': { height: 'auto' },
                                  '& input': {
                                    padding: '$0',
                                  },
                                })}
                                onChange={e => {
                                  setTextfieldTitle(e.target.value)
                                }}
                                onBlur={() => {
                                  setTitleToEditConversationId(null)
                                  onUpdateConversationTitle && onUpdateConversationTitle(conversationId, textfieldTitle)
                                }}
                                onKeyDown={e => {
                                  //if esc key pressed, don't save new value and don't close modal
                                  if (e.key === 'Escape') {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTitleToEditConversationId(null)
                                  }
                                  // if enter key pressed, save new value and close modal
                                  else if (e.key === 'Enter') {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    setTitleToEditConversationId(null)
                                    onUpdateConversationTitle &&
                                      onUpdateConversationTitle(conversationId, textfieldTitle)
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <CustomFlex
                                className={titleRowTextContainer({
                                  isActive: currentSelectedConversationId === conversationId,
                                })}
                                align="center"
                                justify="between"
                                onClick={() => {
                                  onGetConversationById(conversationId)
                                  setCurrentSelectedConversationId(conversationId)
                                  setTitleToEditConversationId(null)
                                }}>
                                <Text
                                  variant="body2"
                                  css={css.raw({
                                    // text doesn't wrap if it's too long
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  })}>
                                  {title}
                                </Text>
                                <div
                                  className="dropdown-container"
                                  onClick={e => {
                                    e.stopPropagation()
                                  }}>
                                  <DropdownMenu
                                    onSelectMenuItem={(item, e) => {
                                      e.stopPropagation()
                                      switch (item.key) {
                                        case 'RENAME':
                                          setTitleToEditConversationId(conversationId)
                                          setTextfieldTitle(title)
                                          setCurrentSelectedConversationId(null)
                                          break
                                        case 'DELETE':
                                          if (
                                            confirm(
                                              'Are you sure you want to delete this conversation? This action cannot be undone.',
                                            )
                                          ) {
                                            onDeleteConversation(conversationId)
                                            setCurrentSelectedConversationId(null)
                                          }
                                          break
                                        default:
                                          break
                                      }
                                    }}
                                    onOpenChange={isOpen => {
                                      if (!isOpen) {
                                        setCurrentSelectedConversationId(null)
                                      } else {
                                        setCurrentSelectedConversationId(conversationId)
                                      }
                                    }}
                                    options={[
                                      {
                                        label: 'Rename',
                                        key: 'RENAME',
                                        reactIcon: <Pencil1Icon />,
                                      },
                                      {
                                        label: 'Delete',
                                        key: 'DELETE',
                                        reactIcon: <TrashIcon />,
                                      },
                                    ]}>
                                    <Icon css={moreHorizIconStyled} size={'18'} reactIcon={<DotsHorizontalIcon />} />
                                  </DropdownMenu>
                                </div>
                              </CustomFlex>
                            )}
                          </div>
                        </div>
                      )
                    })
                )}
              </div>
            </div>
            <div className={chatsColumn}>
              <div className={columnContainerForMessages}>
                {isLoadingConversation &&
                !chatHistory?.find(({ id: conversationId }) => conversationId === currentSelectedConversationId)
                  ?.messages?.length ? (
                  <Box>
                    <Skeleton height="h2" verticalSpacing="none" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                    <Skeleton height="body1" verticalSpacing="medium" color="inverted" />
                  </Box>
                ) : (
                  currentSelectedConversationId &&
                  chatHistory
                    ?.find(({ id: conversationId }) => conversationId === currentSelectedConversationId)
                    ?.messages?.map(message => {
                      return (
                        <ConversationMessage
                          key={message.id}
                          message={message}
                          role={message.role}
                          isFullScreen={isFullScreen}
                          chatbotProfileImage={chatbotProfileImage}
                          userFirstName={userFirstName}
                          userLastName={userLastName}
                          isVisible={true}
                        />
                      )
                    })
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  ) : null
}

const getTitleToDisplayFromTimestamp = (timestamp: number): string => {
  let time = ''
  // if timestamp is less than 1 day ago
  if (Date.now() - timestamp < 1000 * 60 * 60 * 24) {
    time = 'Today'
  } else if (Date.now() - timestamp < 1000 * 60 * 60 * 24 * 7) {
    // timestamp is less than 7 days ago
    time = 'Previous 7 Days'
  } else if (Date.now() - timestamp < 1000 * 60 * 60 * 24 * 30) {
    // if is less than 30 days ago
    // display day of week
    time = 'Previous 30 Days'
  } else {
    // if timestamp is more than 1 year ago
    if (new Date(timestamp).getFullYear() < new Date().getFullYear()) {
      time = 'Previous years'
    } else {
      // display month
      time = new Date(timestamp).toLocaleString('default', { month: 'long' })
    }
  }
  return time
}

// const MoreHorizIconStyled = styled(Icon, {
const moreHorizIconStyled = css.raw({
  padding: '[2px]',
  borderRadius: '$3',
  transitionProperty: 'all',
  transitionDuration: '$normal',
  transitionTimingFunction: 'in-out',
  _hover: {
    backgroundColor: '$gs6',
  },
})

// const Container = styled('div', {
const container = cva({
  base: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'stretch',
    width: '$full',
    // maxHeight: 'calc(85vh - 200px)',
    // minHeight: 'max(50vh, 400px)',
    height: '[max(50vh, 400px)]',
    overflowY: 'hidden',
  },
  variants: {
    centerContent: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
      false: {},
    },
  },
  defaultVariants: {
    centerContent: false,
  },
})

// const TitleRow = styled('div', {
const titleRow = cva({
  variants: {
    isSelected: {
      true: {
        backgroundColor: '$gs5',
      },
      false: {},
    },
  },
  defaultVariants: {
    isSelected: false,
  },
})

// const TitleRowTextContainer = styled(CustomFlex, {
const titleRowTextContainer = cva({
  base: {
    width: '$full',
    padding: '$1',
    borderRadius: '$3',
    position: 'relative',
    '& .dropdown-container': {
      visibility: 'hidden',
    },
    _hover: {
      cursor: 'pointer',
      backgroundColor: '$gs3',
      '& .dropdown-container': {
        visibility: 'visible',
      },
    },
  },
  variants: {
    isActive: {
      true: {
        backgroundColor: '$gs3',
        '& .dropdown-container': {
          visibility: 'visible',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    isActive: false,
  },
})

// const TitleColumn = styled('div', {
const titleColumn = css({
  flex: '[0 0 100px]',
  maxWidth: '[100px]',
  color: '$gs12',
  bp1: {
    maxWidth: '[230px]',
    flex: '[0 0 230px]',
  },
})

// const ChatsColumn = styled('div', {
const chatsColumn = css({
  backgroundColor: '$gs2',
  color: '$gs1',
  flex: '[1 1 0%]',
  marginBottom: '$6',
  '& > *': {
    padding: '$2',
  },
})
// const ColumnContainerForTitles = styled('div', {
const columnContainerForTitles = css({
  height: '$full',
  overflowY: 'auto',
  pr: '$2',
})

// const ColumnContainerForMessages = styled('div', {
const columnContainerForMessages = css({
  height: '$full',
  overflowY: 'auto',
  display: 'flex',
  flexFlow: 'column',
  gap: '$7',
  boxSizing: 'border-box',
})
