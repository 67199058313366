'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import React, { ReactNode, useEffect, useState } from 'react'
import { css, cva, cx } from 'styled-system/css'
import { type SystemStyleObject } from 'styled-system/types'

import { Button, ButtonProps } from './Button'
import { Icon } from './Icon'
import { ScrollArea } from './ScrollArea'
import { Text } from './Text'

export type ModalSizeTypes = 'xs' | 'small' | 'default' | 'large'

export interface ModalProps {
  /**
   * HTML element to open the modal.
   */
  openButton?: React.ReactNode

  /**
   * Short text to clarify a thought for the modal.
   */
  tagline?: string

  /**
   * The state of the modal.
   */
  open?: boolean

  /**
   * Title of the modal.
   */
  title?: string

  /**
   * Subordinate title of the modal.
   */
  subtitle?: string

  /**
   * Content for the modal.
   */
  children?: React.ReactNode

  /**
   * Disable the open button.
   */
  disabled?: boolean

  /**
   * The primary button's label.
   */
  primaryBtnLabel?: string

  /**
   * The secondary button's label.
   */
  secondaryBtnLabel?: string

  /**
   * The tertiary button's label.
   */
  tertiaryBtnLabel?: string

  /**
   * disabled primary button.
   */
  disablePrimaryBtn?: boolean

  /**
   * Size of the modal.
   */
  size?: ModalSizeTypes

  /**
   * Allows scroll the modal body.
   */
  scrollable?: boolean

  /**
   * The max height of the modal.
   */
  maxHeight?: number

  /**
   * Give css class name to modal component.
   */
  className?: string

  /**
   * Give css property to modal component.
   */
  css?: SystemStyleObject

  /**
   * Get a callback when primary button is clicked.
   */
  primaryBtnCallback?: (e) => void | Promise<void | boolean>

  /**
   * Get a callback when secondary button is clicked.
   */
  secondaryBtnCallback?: (e) => void | Promise<void | boolean>

  /**
   * Get a callback when the modal is opened or closed.
   */
  onOpenChange?: (open: boolean) => void
}

export const Modal: React.FC<ModalProps> = ({
  openButton = null,
  tagline = null,
  title = null,
  subtitle = null,
  children = null,
  size = 'default',
  scrollable = false,
  className = '',
  css: cssProp = {},
  tertiaryBtnLabel,
  primaryBtnLabel = null,
  secondaryBtnLabel = null,
  primaryBtnCallback = null,
  secondaryBtnCallback = null,
  open = null,
  disabled = false,
  disablePrimaryBtn = false,
  onOpenChange = (open: boolean) => {},
}) => {
  const [state, setState] = useState<boolean>(open !== null ? open : false)
  const [primaryBtnState, setPrimaryBtnState] = useState<ButtonProps['buttonState']>('default')
  const [secondaryBtnState, setSecondaryBtnState] = useState<ButtonProps['buttonState']>('default')

  const btnAttribute = { as: 'span' }

  useEffect(() => {
    if (open !== null) {
      setState(open)
    }
  }, [open])

  const handlePrimaryCallback = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    setPrimaryBtnState('waiting')
    if (primaryBtnCallback) {
      try {
        const res = await primaryBtnCallback(e)
        if (res === undefined || res === true) {
          onOpenChange(false)
          setState(false)
        }
      } catch (error) {
        console.error('error in primaryBtnCallback of Modal:', error)
      } finally {
        setPrimaryBtnState('default')
      }
    }
  }

  const handleSecondaryCallback = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    setSecondaryBtnState('waiting')
    if (secondaryBtnCallback) {
      try {
        const res = await secondaryBtnCallback(e)
        if (res === undefined || res === true) {
          onOpenChange(false)
          setState(false)
        }
      } catch (error) {
        console.error('error in secondaryBtnCallback of Modal:', error)
      } finally {
        setSecondaryBtnState('default')
      }
    }
  }

  const handleOpenChange = (state: boolean) => {
    setState(state)
    onOpenChange(state)
  }

  const tertiaryBtnVariant = secondaryBtnLabel && secondaryBtnLabel ? 'ghost' : 'tertiary'

  return (
    <ModalRoot onOpenChange={handleOpenChange} open={state}>
      <ModalTrigger disabled={disabled} asChild>
        {openButton}
      </ModalTrigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Content
          className={cx(
            modalContainer({
              size,
            }),
            css(cssProp),
            className,
          )}>
          <DialogPrimitive.Title className={modalHeader}>
            <div className={modalTitles}>
              {tagline && <Text variant="caption">{tagline}</Text>}
              <Text
                variant="subtitle1"
                css={{
                  color: '$gs12',
                }}>
                {title}
              </Text>
              <Text oll={false} variant="body2">
                {subtitle}
              </Text>
            </div>
            <DialogPrimitive.Close asChild className={modalClose}>
              <Icon
                reactIcon={<Cross1Icon />}
                css={{
                  borderWidth: '$1',
                  borderStyle: 'solid',
                  borderColor: '$gs12',
                  borderRadius: '$3',
                  color: '$gs12',
                  _hover: {
                    opacity: '0.8',
                    transitionProperty: 'all',
                    transitionDuration: '$fast',
                    transitionTimingFunction: 'in-out',
                  },
                }}
              />
            </DialogPrimitive.Close>
          </DialogPrimitive.Title>
          {scrollable ? (
            <ScrollArea maxHeight={230}>
              <div className={modalContent}>
                {React.Children.map<ReactNode, ReactNode>(children, child => {
                  if (React.isValidElement(child)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return React.cloneElement<any>(child)
                  }
                })}
              </div>
            </ScrollArea>
          ) : (
            <div className={modalContent}>
              {React.Children.map<ReactNode, ReactNode>(children, child => {
                if (React.isValidElement(child)) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  return React.cloneElement<any>(child)
                }
              })}
            </div>
          )}
          <div className={modalFooter}>
            <DialogPrimitive.Close asChild className={modalClose}>
              {tertiaryBtnLabel && (
                <Button attributes={btnAttribute} label={tertiaryBtnLabel} variant={tertiaryBtnVariant} />
              )}
            </DialogPrimitive.Close>
            {secondaryBtnLabel && (
              <Button
                label={secondaryBtnLabel}
                onClick={handleSecondaryCallback}
                variant="primary"
                buttonState={secondaryBtnState}
              />
            )}
            {primaryBtnLabel && (
              <Button
                disabled={disablePrimaryBtn}
                label={primaryBtnLabel}
                onClick={handlePrimaryCallback}
                variant="secondary"
                buttonState={primaryBtnState}
              />
            )}
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </ModalRoot>
  )
}

const modalTitles = css({
  color: '$gs10',
  flex: '[1 1 auto]',
  overflow: 'hidden',
})

const modalHeader = css({
  display: 'flex',
  alignItems: 'center',
  p: '$7',
})

const modalClose = css({
  mx: '$1',
})

const modalContainer = cva({
  base: {
    backgroundColor: '$gs1',
    borderWidth: '$1',
    borderStyle: 'solid',
    borderColor: '$gs10',
    position: 'fixed',
    top: '[50%]',
    left: '[50%]',
    transform: 'translate(-50%, -50%)',
    width: '[90vw]',
    maxWidth: '[min(calc(100vw), 1200px)]',
    maxHeight: '[85vh]',
    display: 'flex',
    zIndex: '[1001]',
    flexDirection: 'column',
    _focus: {
      outline: 'none',
    },
    '@media (prefers-reduced-motion: no-preference)': {
      animationName: 'modalContentShow',
      animationDuration: '[300ms]',
      animationTimingFunction: '[cubic-bezier(0.16, 1, 0.3, 1)]',
      willChange: 'transform',
    },
  },
  variants: {
    size: {
      xs: {
        bp1: { maxWidth: '[min( calc( 100vw * 0.48 ), 1200px )]' },
        bp2: { maxWidth: '[min( calc( 100vw * 0.32 ), 1200px )]' },
        bp3: { maxWidth: '[min( calc( 100vw * 0.24 ), 1200px )]' },
        bp4: { maxWidth: '[min( calc( 100vw * 0.24 ), 1200px )]' },
      },
      small: {
        bp1: { maxWidth: '[min( calc( 100vw * 0.60 ), 1200px )]' },
        bp2: { maxWidth: '[min( calc( 100vw * 0.42 ), 1200px )]' },
        bp3: { maxWidth: '[min( calc( 100vw * 0.36 ), 1200px )]' },
        bp4: { maxWidth: '[min( calc( 100vw * 0.36 ), 1200px )]' },
      },
      default: {
        bp1: { maxWidth: '[min( calc( 100vw * 0.84 ), 1200px )]' },
        bp2: { maxWidth: '[min( calc( 100vw * 0.60 ), 1200px )]' },
        bp3: { maxWidth: '[min( calc( 100vw * 0.48 ), 1200px )]' },
        bp4: { maxWidth: '[min( calc( 100vw * 0.48 ), 1200px )]' },
      },
      large: {
        bp1: { maxWidth: '[min( calc( 100vw * 0.96 ), 1200px )]' },
        bp2: { maxWidth: '[min( calc( 100vw * 0.84 ), 1200px )]' },
        bp3: { maxWidth: '[min( calc( 100vw * 0.72 ), 1200px )]' },
        bp4: { maxWidth: '[min( calc( 100vw * 0.72 ), 1200px )]' },
      },
    },
  },
})

const modalContent = css({
  display: 'block',
  px: '$7',
  overflowY: 'auto',
})

const modalFooter = css({
  display: 'flex',
  justifyContent: 'flex-end',
  p: '$7',
  gap: '$3',
})

const ModalTrigger = DialogPrimitive.Trigger

const ModalRoot: React.FC<DialogPrimitive.DialogProps> = ({ children, ...props }) => {
  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Overlay className={styledOverlay} />
      {children}
    </DialogPrimitive.Root>
  )
}

const styledOverlay = css({
  backgroundColor: '$b5',
  zIndex: '[4]',
  position: 'fixed',
  inset: '$0',
  '@media (prefers-reduced-motion: no-preference)': {
    animationName: 'modalOverlayShow',
    animationDuration: '[300ms]',
    animationTimingFunction: '[cubic-bezier(0.16, 1, 0.3, 1)]',
  },
})
