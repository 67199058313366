'use client'

import { Chatbot, ChatbotProps } from '@design-system/src/components/Chatbot'
import { ChatbotClientLoggedInProps } from 'app/sites/[site]/_components/ChatbotClientLoggedIn'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { ComponentType, useMemo } from 'react'
import { PAGES_NEXT_PUBLIC_SITES } from 'src/utils/route-utils'

import { AiModel, ChatbotPageBlacklist, FeaturedChatbotPrompt, Site } from '@models/types'
import { PUBLICATION_INTERNAL_PAGE_SLUGS } from '@shared/constants'

export type ChatbotClientProps = {
  chatbotImage: Site['chatbotImage']
  chatOpenByDefault: ChatbotProps['openByDefault']
  customGreetingMsg: string
  featuredChatbotPrompts: FeaturedChatbotPrompt[] | undefined
  chatbotPageBlacklist?: ChatbotPageBlacklist
}

export const ChatbotClient: React.FC<ChatbotClientProps> = props => {
  const { data: session, status } = useSession()
  const pathname = usePathname()

  const isChatbotVisible = useMemo(
    () => getIsChatbotVisible({ pathname, chatbotPageBlacklist: props.chatbotPageBlacklist }),
    [pathname, props.chatbotPageBlacklist],
  )

  const chatbotImageForChatbot: ChatbotProps['chatbotProfileImage'] = useMemo(() => {
    if (!props.chatbotImage) return undefined
    const { height, width, src } = props.chatbotImage
    if (!height || !width || !src) return undefined
    return { height, width, src }
  }, [props.chatbotImage])

  const ChatbotClientLoggedIn = useMemo<ComponentType<ChatbotClientLoggedInProps> | null>(() => {
    if (status === 'authenticated')
      return dynamic(() =>
        import('app/sites/[site]/_components/ChatbotClientLoggedIn').then(mod => mod.ChatbotClientLoggedIn),
      )
    return null
  }, [status])

  if (pathname.includes(PAGES_NEXT_PUBLIC_SITES.paymentPortalResult)) return null
  if (!isChatbotVisible) return null

  if (status === 'authenticated') {
    if (!ChatbotClientLoggedIn) {
      console.error('ChatbotClientLoggedIn is null but status is authenticated')
      return null
    }

    return (
      <ChatbotClientLoggedIn
        {...props}
        chatbotProfileImage={chatbotImageForChatbot}
        userId={session?.user?.id}
        featuredChatbotPrompts={props.featuredChatbotPrompts}
      />
    )
  }

  if (status === 'loading') {
    return null
  }

  if (status === 'unauthenticated') {
    return (
      <Chatbot
        openByDefault={props.chatOpenByDefault}
        chatbotProfileImage={chatbotImageForChatbot}
        mode="under_paywall"
        handleModelTypeChange={function (modelType: AiModel): void {
          throw new Error('Function not implemented.')
        }}
        handleSendMessage={function (value: string): void {
          throw new Error('Function not implemented.')
        }}
        handlePersonaChange={function (persona: string): void {
          throw new Error('Function not implemented.')
        }}
        messages={[]}
        modelType={'GPT4'}
        personas={[]}
        onResetChatbot={function (): void {
          throw new Error('Function not implemented.')
        }}
        readyState={1}
      />
    )
  }
}

const getIsChatbotVisible = ({
  pathname,
  chatbotPageBlacklist,
}: {
  pathname: string
  chatbotPageBlacklist?: ChatbotPageBlacklist
}): boolean => {
  if (!chatbotPageBlacklist) return true
  if (pathname.includes(PAGES_NEXT_PUBLIC_SITES.signInMagicLinkUrl)) return false

  if (chatbotPageBlacklist.home && pathname === '/') return false
  if (chatbotPageBlacklist.writers && pathname.includes(`${PUBLICATION_INTERNAL_PAGE_SLUGS.writers}/`)) return false
  if (chatbotPageBlacklist.tags && pathname.includes(`${PUBLICATION_INTERNAL_PAGE_SLUGS.tags}/`)) return false
  if (chatbotPageBlacklist.sections && pathname.includes(`${PUBLICATION_INTERNAL_PAGE_SLUGS.sections}/`)) return false
  if (
    chatbotPageBlacklist.latestStories &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.latestStories}`))
  )
    return false
  if (
    chatbotPageBlacklist.newsletters &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.newsletters}`))
  )
    return false
  if (
    chatbotPageBlacklist.signIn &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.signIn}`))
  )
    return false
  if (
    chatbotPageBlacklist.subscribe &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.subscribe}`))
  )
    return false
  if (
    chatbotPageBlacklist.account &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.account}`))
  )
    return false
  if (
    chatbotPageBlacklist.contactUs &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.contactUs}`))
  )
    return false
  if (chatbotPageBlacklist.terms && pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.terms}`)))
    return false
  if (
    chatbotPageBlacklist.privacyPolicy &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.privacyPolicy}`))
  )
    return false
  if (
    chatbotPageBlacklist.thankYou &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.thankYou}`))
  )
    return false
  if (chatbotPageBlacklist.gift && pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.gift}`)))
    return false
  if (
    chatbotPageBlacklist.giftRedeem &&
    pathname.includes(removeTrailingSlash(`${PUBLICATION_INTERNAL_PAGE_SLUGS.giftRedeem}`))
  )
    return false
  return true
}

const removeTrailingSlash = (url: string) => {
  return url.endsWith('/') ? url.slice(0, -1) : url
}
